import React, { FunctionComponent } from "react";
import { Image, ImageProps } from "antd";

export const HomepageLogo: FunctionComponent<ImageProps> = ({
  src,
  preview,
  alt,
}) => {
  return <Image src={src} preview={preview} alt={alt}></Image>;
};
