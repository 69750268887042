import React, { FunctionComponent } from "react";

import { Link, graphql } from "gatsby";
import SEO from "../components/Seo";
import { BaseLayout } from "../components/BaseLayout";
import { FluidObject } from "gatsby-image";
import { colors } from "../theme/variables";
import { routes } from "../routes";
import { HomepageLogo } from "../components/HomepageLogo";
import styled from "@emotion/styled";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  margin: 2.5rem 2rem;
  width: 30rem;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: ${colors.blue.primary};
  font-weight: bold;
  :hover {
    color: ${colors.blue.secondary};
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
`;

const FooterItem = styled.span`
  padding: 0.5rem;
  font-size: 0.9rem;
  color: ${colors.blue.primary};
  font-weight: bold;
`;

const FooterLink = styled(Link)`
  padding: 0.5rem;
  text-decoration: none;
  color: ${colors.blue.primary};
  font-size: 0.9rem;
  font-weight: bold;

  :hover {
    color: ${colors.blue.secondary};
  }
`;

type IndexPageProps = {
  data: {
    logoImage: { childImageSharp: { fluid: FluidObject } };
  };
};

const IndexPage: FunctionComponent<IndexPageProps> = (
  props: IndexPageProps
) => {
  return (
    <>
      <SEO />
      <BaseLayout sider={false}>
        <StyledWrapper>
          <HomepageLogo
            src={props.data.logoImage.childImageSharp.fluid.src}
            preview={false}
            alt={"Logo"}
          />
          <MenuBar>
            <MenuLink to={routes.de.projects}>Projekte</MenuLink>
            <MenuLink to={routes.de.profile}>Profile</MenuLink>
            <MenuLink to={routes.de.contact}>Kontakt</MenuLink>
          </MenuBar>
          <Footer>
            <FooterItem>
              &copy;
              {` ${
                new Date().getFullYear() === 2021
                  ? "2021"
                  : "2021-" + new Date().getFullYear()
              }`}
            </FooterItem>
            <FooterLink to={routes.de.imprint}>Impressum</FooterLink>
            <FooterLink to={routes.de.privacy}>Datenschutzerklärung</FooterLink>
          </Footer>
        </StyledWrapper>
      </BaseLayout>
    </>
  );
};

export default IndexPage;

export const data = graphql`
  {
    logoImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
